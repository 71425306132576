<template>
  <div class="border rounded">
    <div
      v-for="(item, i) in data"
      :key="item.id"
      class="row clickable justify-center items-stretch"
      :class="`${i < data.length - 1 && data.length > 1 ? 'border-bottom' : ''}`"
    >
      <div class="q-pa-sm">
        <q-checkbox
          size="xl"
          :model-value="!!items.find(({ id }) => id === item.id)"
          color="amber-6"
          @click="handleCheck(item)"
        />
      </div>

      <div class="col">
        {{ item.name || 'No name' }}
      </div>
    </div>

    <div class="text-center">
      <q-btn
        color="dark"
        text-color="white"
        class="q-mr-sm"
        :label="$t('Discard')"
        @click="cancel"
      />

      <q-btn
        color="light-blue-9"
        text-color="white"
        :label="$t('Save')"
        @click="save"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DynamicServiceCollection',
  emits: ['click'],
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      items: []
    }
  },
  methods: {
    handleCheck (item) {
      let isFounded = false

      this.items = this.items.reduce((acc, x) => {
        if (x.id == item.id) {
          isFounded = true
          return acc
        }

        acc.push(x)
        return acc
      }, [])

      if (!isFounded) {
        this.items.push(item)
      }

      return this.items
    },
    handleClick (data) {
      const item = {
        type: this.rawData.type,
        event: this.rawData.event,
        data
      }

      this.$emit('click', item)
    },
    cancel () {
      return this.handleClick([])
    },
    save () {
      return this.handleClick(this.items.map(x => x.id))
    }
  }
}
</script>
